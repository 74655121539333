new Vue({
  el: '#menu',
  data: function() {
    return {
      tabs: {
        selected: null
      },
      markets: {
        selected: null
      }
    };
  },
  methods: {
    isSelected(name) {
      if (name === this.tabs.selected) {
        return 'bg-techcapital-gray text-techcapital-red border-t-2 border-white';
      }
    },
    toggleTab(name) {
      if (this.tabs.selected === name) {
        this.tabs.selected = null;
      }else{
        this.tabs.selected = name;
      }
    },
    toggleMarket(name) {
      if (this.markets.selected === name) {
        this.markets.selected = null;
      }else{
        this.markets.selected = name;
      }
    }
  },
});